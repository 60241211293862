// components/atoms/LoadingIndicator.tsx
import React from 'react';
import Spinner from '../../atoms/Spinner';

const MessageFeedLoader: React.FC = () => {
  return (
    <div className="flex justify-center items-center w-full h-64">
      <Spinner active color="blue" size="96" borderWidth={0.16} />
    </div>
  );
};

export default MessageFeedLoader;
