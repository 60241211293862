// components/molecules/EmptyState.tsx
import React from 'react';
import {ChatBubbleLeftRightIcon} from '@ergeon/icons/hero/outline';

const MessageFeedEmpty: React.FC = () => {
  return (
    <div className="flex flex-col items-center gap-2 text-center">
      <ChatBubbleLeftRightIcon className="text-gray-500 size-8" />
      <h6 className="h6">No messages to show</h6>
      <p className="max-w-44 paragraph-2 text-gray-800">We will show your message history here</p>
    </div>
  );
};

export default MessageFeedEmpty;
