import './tailwind.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App/App';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement!);

if (process.env.NODE_ENV === 'development') {
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
} else {
  root.render(<App />);
}
if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/service-worker.js')
    .then(() => console.log('Service Worker registered!'))
    .catch((err) => console.error('Service Worker registration failed:', err));
}
