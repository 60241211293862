import React from 'react';
import Button from '../../atoms/Button';
import {ArrowLeftIcon, ArrowRightIcon} from '@ergeon/icons/hero/micro';
import {SimplePaginationProps} from './types';

const SimplePagination: React.FC<SimplePaginationProps> = ({currentPage, totalPages, onPageChange}) => {
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;

  // Events
  const goToPreviousPage = () => !isFirstPage && onPageChange(currentPage - 1);
  const goToNextPage = () => !isLastPage && onPageChange(currentPage + 1);

  // Render Button
  const renderButton = (label: string, onClick: () => void, disabled: boolean, icon?: JSX.Element) => (
    <Button flavor="regular" taste="line" onClick={onClick} disabled={disabled}>
      {icon}
      {label}
    </Button>
  );

  return (
    <div className="flex sm:flex-row flex-col justify-between items-stretch sm:items-baseline gap-2 w-full text-center text-gray-900 p2">
      {/* Button "Previous" */}
      {renderButton('Previous', goToPreviousPage, isFirstPage, <ArrowLeftIcon className="size-4" />)}

      {/* Current Page */}
      <span className="text-gray-500">
        Page {currentPage} of {totalPages}
      </span>

      {/* Button "Next" */}
      {renderButton('Next', goToNextPage, isLastPage, <ArrowRightIcon className="size-4" />)}
    </div>
  );
};

export default SimplePagination;
