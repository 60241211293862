import React, {HTMLAttributes, ReactNode, useCallback} from 'react';

import Dialog, {DialogButtons, DialogContent, DialogHeader} from '../../organisms/UniversalDialog';

import Button from '../../atoms/Button';
import type {ButtonFlavor} from '../../atoms/Button/types';

export interface DialogInterface extends HTMLAttributes<HTMLDivElement> {
  actionButtonFlavor?: ButtonFlavor;
  active?: boolean;
  cancelBtnName?: string;
  cancelDisabled?: boolean;
  children: ReactNode;
  okBtnName?: string;
  okDisabled?: boolean;
  onCancel?: () => void;
  onClose?: () => void;
  onOk: () => void;
  showButtons?: boolean;
  title: string;
}

/**
 * This component represents a general Dialog window
 * Should be used when need some input or confirmation from user.
 */
const DeprecatedDialog = (props: DialogInterface) => {
  const {
    actionButtonFlavor,
    active: isVisible = false,
    cancelBtnName = 'Cancel',
    cancelDisabled = false,
    children,
    okBtnName = 'Ok',
    okDisabled = false,
    onCancel,
    onClose,
    onOk,
    showButtons = true,
    title,
    ...divProps
  } = props;

  const onDismiss = useCallback(() => {
    onClose ? onClose() : onCancel?.();
  }, [onCancel, onClose]);

  return (
    <Dialog {...divProps} isVisible={isVisible}>
      <DialogHeader {...{title, onClose: onDismiss}} />
      <DialogContent>{children}</DialogContent>
      {showButtons && (
        <DialogButtons>
          {Boolean(onCancel) && !cancelDisabled && (
            <Button className="min-width__is-120" flavor="regular" onClick={onCancel}>
              {cancelBtnName}
            </Button>
          )}
          <Button className="min-width__is-120" flavor={actionButtonFlavor} onClick={onOk} disabled={okDisabled}>
            {okBtnName}
          </Button>
        </DialogButtons>
      )}
    </Dialog>
  );
};

export default DeprecatedDialog;
