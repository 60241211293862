import React, {HTMLAttributes} from 'react';
import kebabCase from 'lodash/kebabCase';

type DataRowProps = {
  defaultValue?: string | number | JSX.Element;
  title: string | JSX.Element;
  value?: string | number | JSX.Element;
} & HTMLAttributes<HTMLDivElement>;

const DataRow = (props: DataRowProps) => {
  const {title, value, defaultValue, ...divAttr} = props;
  return (
    <div {...divAttr} className="flex flex-row items-baseline gap-2 font-base leading-5">
      <div className="text-right w-24 text-gray-600 text-xs uppercase tracking-wide">{title}</div>
      <div className="text-gray-900 text-sm" data-qa={`${kebabCase(title)}-value`}>
        {value || defaultValue}
      </div>
    </div>
  );
};

export default DataRow;
