import React, {HTMLAttributes, useCallback} from 'react';

import classNames from 'classnames';
import {ListItem} from './types';
import {DropdownListItem} from './ListItem';
import {DropdownItemTypeEnum} from './contants';

import './index.scss';

type DropdownListProps = {
  list: ListItem[];
  isSubMenu?: boolean;
} & HTMLAttributes<HTMLUListElement>;

/**
 * This a dropdown list component. We use it for rendering a list of actions with icons or without
 * In general this component should be used as a dropdown.
 */
const DropdownList = (props: DropdownListProps) => {
  const {list, className, isSubMenu = false, ...ulAttr} = props;

  const handleClick = useCallback((item: ListItem) => {
    if (!item.disabled) {
      item.onClick?.();
    }
  }, []);

  const isDivider = useCallback((item: ListItem): boolean => item.type === DropdownItemTypeEnum.Divider, []);
  const isGroupTitle = useCallback((item: ListItem): boolean => item.type === DropdownItemTypeEnum.GroupTitle, []);
  const isListItem = useCallback(
    (item: ListItem): boolean => !item.type || item.type === DropdownItemTypeEnum.ListItem,
    [],
  );

  return (
    <ul {...ulAttr} className={classNames('dropdown-list', className, {'dropdown-list--submenu': isSubMenu})}>
      {list.map((item, index) => {
        const hasChildren = Array.isArray(item.children) && item.children.length > 0;
        return (
          <li
            className={classNames(
              'dropdown-list--row',
              {disabled: item.disabled},
              {divider: isDivider(item)},
              {'group-title': isGroupTitle(item)},
              {'has-children': hasChildren}
            )}
            key={`${item.label}${index}`}
            onClick={() => (isListItem(item) ? handleClick(item) : undefined)}
          >
            <DropdownListItem item={item} hasChildren={hasChildren} />
            {hasChildren && <DropdownList list={item.children!} isSubMenu={true} />}
          </li>
        );
      })}
    </ul>
  );
};

export default DropdownList;
