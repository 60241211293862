// Fallback component if no images are available
import {CameraIcon} from '@ergeon/icons/hero/solid';
import React from 'react';

const ImageFallback: React.FC = () => (
  <div className="absolute flex justify-center items-center w-full h-full">
    <CameraIcon className="m-auto fill-gray-400 size-12" />
  </div>
);

export default ImageFallback;
