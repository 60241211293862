import React from 'react';
import Card from '../../atoms/Card';
import moment from 'moment';
import classNames from 'classnames';
import {MessageItemProps} from './types';

const MessageItem: React.FC<MessageItemProps> = ({id, text, dateTime, status}) => {
  // Container Alignment
  const containerClasses = classNames('flex flex-col gap-1 erg-message-item', {
    'self-end items-end': status === 'sent',
    'self-start items-start': status !== 'sent',
  });

  // Message Color
  const cardFlavor = status === 'sent' ? 'success' : 'info';

  // Date and Time Format using moment
  const formattedDateTime = moment(dateTime).format('MMM D, YYYY, hh:mm A');

  return (
    <div key={id} className={containerClasses}>
      <Card rounded="xsmall" className="px-4 py-2 max-w-md paragraph-2 text-gray-900" flavor={cardFlavor}>
        {text}
      </Card>

      <div className="flex items-baseline gap-1">
        <span className="paragraph-3 text-gray-500">{formattedDateTime}</span>
      </div>
    </div>
  );
};

export default MessageItem;
