import React, {useState, useEffect} from 'react';
import SimplePagination from '../../molecules/SimplePagination';
import MessageItem from './MessageItem';
import MessageFeedEmpty from './MessageFeedEmpty';
import MessageFeedLoader from './MessageFeedLoader';
import {MessageItemProps} from './types';

interface MessageFeedProps {
  messages: MessageItemProps[];
}

const MESSAGES_PER_PAGE = 10;

const MessageFeed: React.FC<MessageFeedProps> = ({messages}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const totalPages = Math.ceil(messages.length / MESSAGES_PER_PAGE);
  const currentMessages = messages.slice((currentPage - 1) * MESSAGES_PER_PAGE, currentPage * MESSAGES_PER_PAGE);

  const renderMessages = () =>
    currentMessages.length === 0 ? (
      <MessageFeedEmpty />
    ) : (
      currentMessages.map((message) => <MessageItem key={message.id} {...message} />)
    );

  return (
    <div className="flex flex-col gap-4 erg-message-feed">
      {isLoading ? (
        <MessageFeedLoader />
      ) : (
        <>
          {renderMessages()}
          {totalPages > 1 && (
            <SimplePagination currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
          )}
        </>
      )}
    </div>
  );
};

export default MessageFeed;
